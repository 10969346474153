exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-all-wp-post-tsx": () => import("./../../../src/templates/allWpPost.tsx" /* webpackChunkName: "component---src-templates-all-wp-post-tsx" */),
  "component---src-templates-all-wp-project-tsx": () => import("./../../../src/templates/allWpProject.tsx" /* webpackChunkName: "component---src-templates-all-wp-project-tsx" */),
  "component---src-templates-wp-page-tsx": () => import("./../../../src/templates/WpPage.tsx" /* webpackChunkName: "component---src-templates-wp-page-tsx" */),
  "component---src-templates-wp-post-tsx": () => import("./../../../src/templates/WpPost.tsx" /* webpackChunkName: "component---src-templates-wp-post-tsx" */),
  "component---src-templates-wp-project-tsx": () => import("./../../../src/templates/WpProject.tsx" /* webpackChunkName: "component---src-templates-wp-project-tsx" */),
  "component---src-templates-wp-team-member-tsx": () => import("./../../../src/templates/WpTeamMember.tsx" /* webpackChunkName: "component---src-templates-wp-team-member-tsx" */)
}

