import React from 'react';
import { HidingHeader } from 'hiding-header-react';
import DesktopHeader from './menus/desktop-header';
import 'hiding-header/dist/style.css';
import MobileHeader from './menus/mobile-header';

const Header: React.FC = () => (
    <>
        {/* <HidingHeader> */}
        <header
            css={{
                '@media (max-width: 767px)': {
                    display: 'none',
                },
                zIndex: '10',
                top: 0,
                position: 'sticky',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px 0',
                backgroundColor: 'var(--color-shades-white)',
            }}
        >
            <DesktopHeader />
        </header>
        {/* </HidingHeader> */}
        <MobileHeader />
    </>
);

export default Header;
