import React from 'react';
import { useLocation } from '@reach/router';

interface IHeaderContext {
    show: boolean
    title: string
    slides: number
    currentSlide: number
    openGallery: ({ slides, currentSlide, title }: { slides: number, currentSlide: number, title: string }) => void
    closeGallery: () => void
    setSlide: ({ slide }: { slide: number }) => void
}

const HeaderContext = React.createContext<IHeaderContext>({} as any);

const HeaderProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const [gallery, setGallery] = React.useState({
        show: false,
        slides: 0,
        currentSlide: 0,
        title: '',
    });

    const openGallery = ({ slides, currentSlide, title }:
    { slides: number, currentSlide: number, title: string }): void => {
        setGallery({
            show: true,
            slides,
            currentSlide,
            title,
        });
    };

    const closeGallery = (): void => {
        setGallery({
            show: false,
            slides: 0,
            currentSlide: 0,
            title: '',
        });
    };

    const { pathname } = useLocation();

    React.useEffect(() => {
        if (gallery.show) {
            setTimeout(() => {
                closeGallery();
            }, 500)
        }
    }, [pathname]);

    const setSlide = ({ slide }: { slide: number }): void => {
        setGallery(e => ({
            ...e,
            currentSlide: slide,
        }));
    };

    const value = React.useMemo(() => ({
        ...gallery,
        setSlide,
        openGallery,
        closeGallery,
    }), [gallery]);

    return (
        <HeaderContext.Provider value={value}>
            {children}
        </HeaderContext.Provider>
    );
};

export default HeaderProvider;

export const useHeaderContext = (): IHeaderContext => React.useContext(HeaderContext);
